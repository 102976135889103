import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/src/app/[locale]/Provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/src/components/landing/plugins/AOSInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/frontend/LuckyNetwork/LuckyNetwork-LandingWebV5/src/components/NextIntlProvider.tsx");
